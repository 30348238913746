import React from "react"

import data from "../jobs.json"
import Title from "../components/UI/Title"
import variables from "./GlobalVariables"
import styled from "styled-components"

const DetailStyles = styled.div`
  width: 100%;

  details {
    margin-top: 40px;
    border-bottom: var(--accentColor) 2px solid;
    padding: 10px;
    box-sizing: border-box;
  }

  summary {
  }

  @media (max-width: ${variables.smallScreen}) {
    summary {
    }
  }
`

const PTag = styled.p`
  margin: 0;
`

const ListItem = styled.li`
  color: ${variables.clearColor};
  text-align: left;
  list-style-type: circle;
  padding: 5px 0;
`

const work = () => {
  const singleJob = data.map(job => {
    const responsability = Object.keys(job.Duties).map(resItem => {
      return <ListItem key={Math.random() * 1}>{job.Duties[resItem]}</ListItem>
    })

    return (
      <DetailStyles>
        <details>
          <summary>
            {job.Company} - {job.Title}
          </summary>
          <p>{job.From}</p>
          <PTag>{job.Location}</PTag>
          <ul>{responsability}</ul>
        </details>
      </DetailStyles>
    )
  })
  return <>{singleJob}</>
}

export default work
