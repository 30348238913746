import React from "react"

import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Title from "../components/UI/Title"
import Work from "../components/Work"
import variables from "../components/GlobalVariables"
import SkillImages from "../components/UI/SkillImages"
import CSUN from "../images/CSUN_LOGO_VERTICAL_186.svg"

const LayoutStyleAbout = styled.div`
  margin: 0 auto;
  width: 60%;
  text-align: left;

  .container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .onFull {
    display: none;
  }

  .educationContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;
  }

  .text {
    text-align: left;
    margin: 0;
  }

  .textWork {
    text-align: left;
    width: 100%;
  }

  .csunLogo {
    width: 30%;
    margin: 0 auto;
  }

  @media (max-width: ${variables.mediumScreen}) {
    top: 8vh;
  }

  @media (max-width: ${variables.smallScreen}) {
    margin: 10vh auto;
    width: 90%;
    text-align: left;
    flex-direction: column;

    .onFull {
      display: block;
    }

    .educationContainer {
      flex-wrap: wrap;
    }
  }
`

const IndexPage = props => {
  const imageSkills = props.data.allFile.edges

  return (
    <Layout>
      <SEO title="About" />
      <LayoutStyleAbout>
        <div className="container onFull">
          <Title size="l">Skills</Title>
          <p>
            These are some of the technologies that i use for projects that I
            work on or client websites.{" "}
          </p>
          <SkillImages images={imageSkills} />
        </div>
        <div className="container">
          <div className="educationContainer">
            <div className="text">
              <Title size="l">Education</Title>
              <Title size="m">California State University, Northridge</Title>
              <p>May, 2018</p>
              <p>Bachelor of Science in Computer Information Technology</p>
              <p>Minor in Graphic Design</p>
            </div>
            <img
              className="csunLogo"
              src={CSUN}
              alt="California State University Northridge Alumni"
            />
          </div>
          <div className="textWork">
            <Title size="l">Work History</Title>
          </div>
          <Work />
        </div>
      </LayoutStyleAbout>
    </Layout>
  )
}

export const query = graphql`
  {
    __typename
    allFile(filter: { relativeDirectory: { eq: "skills" } }) {
      edges {
        node {
          childImageSharp {
            id
            original {
              src
            }
          }
          name
        }
      }
    }
  }
`

export default IndexPage
